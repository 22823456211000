import { storeToRefs } from 'pinia'
import type { CompanyResponse } from '~/schemas'

export function useSelectedCompany() {
    const authStore = useAuthStore()

    const { selectedCompany } = storeToRefs(authStore)

    if (!selectedCompany.value) throw new Error('No selected company')

    const company = selectedCompany as ComputedRef<CompanyResponse>

    return {
        company,
    }
}
